var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      [
        _c(
          "v-layout",
          { staticClass: "my-auto" },
          [
            _vm.payload.showScanCode
              ? _c("barcode-scanner", {
                  attrs: {
                    search: _vm.search,
                    scannerPayload: _vm.scannerPayload,
                    loading: _vm.scannerFieldLoading,
                  },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                })
              : _c("v-text-field", {
                  staticClass: "pa-0",
                  attrs: {
                    solo: "",
                    label: _vm.$t("message.common.search"),
                    "hide-details": "",
                    dense: "",
                  },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                }),
          ],
          1
        ),
        _c(
          "v-card-title",
          { staticClass: "pa-0 pl-1 mt-1" },
          [
            _vm.payload.addBtnTo &&
            _vm.checkActionPermission(_vm.payload.module, _vm.CONSTANTS.CREATE)
              ? _c(
                  "v-btn",
                  {
                    staticClass: "ml-3",
                    attrs: {
                      color: "primary",
                      fab: "",
                      "x-small": "",
                      id: "newbtnTo",
                      to: _vm.payload.addBtnTo,
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-plus")])],
                  1
                )
              : _vm._e(),
            _c("v-spacer"),
            _c("h5", [
              _vm._v(
                _vm._s(
                  _vm.payload.isCustomModules
                    ? _vm.payload.moduleTitle
                    : _vm.$t(_vm.payload.moduleTitle)
                )
              ),
            ]),
            _c("v-spacer"),
            _c("h6", { staticClass: "mr-4" }, [
              _vm._v(
                _vm._s(_vm.payload.list.length) +
                  " " +
                  _vm._s(_vm.$t("message.common.of")) +
                  " " +
                  _vm._s(_vm.payload.total)
              ),
            ]),
          ],
          1
        ),
      ],
      _c(
        "v-card-text",
        { staticClass: "pa-0 mt-2 mb-4" },
        [
          _c("v-virtual-scroll", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.payload.list.length,
                expression: "payload.list.length",
              },
            ],
            key: _vm.key,
            attrs: {
              items: _vm.payload.list,
              "item-height": _vm.payload.width,
              "max-height": "65vh",
              id: _vm.id,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-card",
                      {
                        staticClass: "mt-1 pa-1",
                        attrs: { outlined: "", color: "#e8eaf6" },
                        on: {
                          click: function ($event) {
                            _vm.isList ? _vm.navigateOrder(item.id) : false
                          },
                        },
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pa-1 pt-0 pb-0" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0 my-auto",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(_vm._s(item.number)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "pa-0", attrs: { cols: "7" } },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _vm.type === "supplier"
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.suppliername
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : _vm.type === "transfer"
                                              ? [
                                                  _vm._v(
                                                    " S: " +
                                                      _vm._s(
                                                        item.sourcewarehouse
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : _vm.type === "adjustment"
                                              ? [
                                                  _vm._v(
                                                    " W: " +
                                                      _vm._s(
                                                        item.warehousename
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        _vm.type === "transfer"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pa-0",
                                                attrs: { cols: "12" },
                                              },
                                              [
                                                _vm._v(
                                                  " D: " +
                                                    _vm._s(item.destwarehouse) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { cols: "7" },
                                          },
                                          [
                                            _vm._v(
                                              " O: " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    item.orderdate
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.isList &&
                                        (_vm.type === "supplier" ||
                                          _vm.type === "adjustment")
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 text-end pr-2",
                                                attrs: { cols: "5" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "message.product.qty"
                                                      )
                                                    ) +
                                                    ": "
                                                ),
                                                _vm.type === "supplier"
                                                  ? [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "absoluteNumber"
                                                          )(item.receivedqty)
                                                        )
                                                      ),
                                                    ]
                                                  : _vm.type === "adjustment"
                                                  ? [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "absoluteNumber"
                                                          )(item.qty)
                                                        )
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0 my-auto text-right",
                                    attrs: { cols: "2" },
                                  },
                                  [
                                    _vm.isList
                                      ? _c(
                                          "v-btn",
                                          { attrs: { icon: "" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _c("h4", [
                                          _vm._v(
                                            " +" +
                                              _vm._s(
                                                _vm._f("absoluteNumber")(
                                                  item.receivedqty
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "p",
            { staticClass: "text-center px-5 py-0" },
            [
              _vm.payload.loading
                ? _c("v-progress-linear", {
                    attrs: {
                      color: "deep-purple accent-4",
                      indeterminate: "",
                      rounded: "",
                      height: "6",
                    },
                  })
                : _vm._e(),
              false
                ? _c("v-progress-circular", {
                    staticClass: "mx-auto",
                    attrs: { indeterminate: "", color: "primary" },
                  })
                : _vm._e(),
            ],
            1
          ),
          !_vm.payload.loading && _vm.payload.list.length === 0
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-2",
                  attrs: { prominent: "", text: "", "item.type": "info" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }